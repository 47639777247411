import Rails from "@rails/ujs"
import "../stylesheets/landing";
import 'bootstrap/js/dist/modal';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../app';
require.context('../images', true);

Rails.start()

export * from '../global'
